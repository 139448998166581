<template>
  <div class="weChatOrdering_box">
    <!-- 我的订餐 -->
    <van-nav-bar
      :title="'订餐'"
      left-text
      left-arrow
      @click-left="onClickLeft"
      :fixed="true"
    />
    <div
      class="content"
      :class="canOrder && menuinfo.length > 0 ? '' : 'noFoot'"
    >
      <div class="head_date">
        <div class="icon_box" @click="arrows('left')">
          <van-icon name="arrow-left" />
        </div>
        <div
          class="date_box"
          v-for="(it, idx) in weeks"
          :key="idx"
          @click="getOneDay(it)"
        >
          <div class="week">{{ it.week }}</div>
          <div class="date">
            <div class="text" :class="checkdate == it.date ? 'bgtext' : ''">
              {{ it.date }}
            </div>
          </div>
        </div>
        <div class="icon_box" @click="arrows('right')">
          <van-icon name="arrow" />
        </div>
      </div>
      <div v-if="!loading">
        <div v-if="menuinfo.length > 0">
          <!-- 餐别 -->
          <div
            class="comm_food_box"
            v-for="(item, idx) in menuinfo"
            :key="idx"
            :style="{ background: item.ys.bg }"
          >
            <div class="food_head">
              <div class="head_left" :style="{ color: item.ys.headLcolor }">
                <svg-icon :icon-class="item.ys.icon"></svg-icon>
                <div>{{ item.xfname }}</div>
              </div>
              <div class="head_right">
                <div
                  v-if="item.zcChecked[0]"
                  class="checkd_food"
                  :style="{ background: item.ys.headRbg }"
                >
                  已选：{{ item.zcChecked.join(',') }}套餐
                </div>
                <!-- <div v-else
                 class="checkd_food">待选择</div> -->
              </div>
            </div>
            <div class="food_body">
              <div class="body_left">
                <div class="left_title">
                  主菜
                  <span style="font-size:14px;color:red;">{{
                    item.zcDesc
                  }}</span>
                </div>
                <van-checkbox-group
                  v-model="item.zcChecked"
                  :max="item.zc_max_select || 0"
                  :disabled="!canOrder || !item.can_select_zc"
                  @change="zcchange(item)"
                >
                  <van-checkbox
                    v-if="item.zcflag1"
                    :name="item.zcflag1"
                    :checked-color="item.ys.headLcolor"
                  >
                    <template #default>
                      <div class="en">{{ item.zcflag1 }}</div>
                      <div class="fo">{{ item.zcname1 }}</div>
                    </template>
                  </van-checkbox>
                  <van-checkbox
                    v-if="item.zcflag2"
                    :name="item.zcflag2"
                    :checked-color="item.ys.headLcolor"
                  >
                    <template #default>
                      <div class="en">{{ item.zcflag2 }}</div>
                      <div class="fo">{{ item.zcname2 }}</div>
                    </template>
                  </van-checkbox>
                  <van-checkbox
                    v-if="item.zcflag3"
                    :name="item.zcflag3"
                    :checked-color="item.ys.headLcolor"
                  >
                    <template #default>
                      <div class="en">{{ item.zcflag3 }}</div>
                      <div class="fo">{{ item.zcname3 }}</div>
                    </template>
                  </van-checkbox>
                  <van-checkbox
                    v-if="item.zcflag4"
                    :name="item.zcflag4"
                    :checked-color="item.ys.headLcolor"
                  >
                    <template #default>
                      <div class="en">{{ item.zcflag4 }}</div>
                      <div class="fo">{{ item.zcname4 }}</div>
                    </template>
                  </van-checkbox>
                  <van-checkbox
                    v-if="item.zcflag5"
                    :name="item.zcflag5"
                    :checked-color="item.ys.headLcolor"
                  >
                    <template #default>
                      <div class="en">{{ item.zcflag5 }}</div>
                      <div class="fo">{{ item.zcname5 }}</div>
                    </template>
                  </van-checkbox>
                </van-checkbox-group>
              </div>
              <div class="body_right">
                <div class="right_title">
                  辅菜
                  <span style="font-size:14px;color:red;">{{
                    item.fcDesc
                  }}</span>
                </div>
                <van-checkbox-group
                  v-model="item.fcChecked"
                  :max="item.fc_max_select || 0"
                  :disabled="!canOrder || !item.can_select_fc"
                >
                  <van-checkbox
                    v-if="item.fcflag1"
                    :name="item.fcflag1"
                    :checked-color="item.ys.headLcolor"
                  >
                    <template #default>
                      <div class="en">{{ item.fcflag1 }}</div>
                      <div class="fo">{{ item.fcname1 }}</div>
                    </template>
                  </van-checkbox>
                  <van-checkbox
                    v-if="item.fcflag2"
                    :name="item.fcflag2"
                    :checked-color="item.ys.headLcolor"
                  >
                    <template #default>
                      <div class="en">{{ item.fcflag2 }}</div>
                      <div class="fo">{{ item.fcname2 }}</div>
                    </template>
                  </van-checkbox>
                  <van-checkbox
                    v-if="item.fcflag3"
                    :name="item.fcflag3"
                    :checked-color="item.ys.headLcolor"
                  >
                    <template #default>
                      <div class="en">{{ item.fcflag3 }}</div>
                      <div class="fo">{{ item.fcname3 }}</div>
                    </template>
                  </van-checkbox>
                  <van-checkbox
                    v-if="item.fcflag4"
                    :name="item.fcflag4"
                    :checked-color="item.ys.headLcolor"
                  >
                    <template #default>
                      <div class="en">{{ item.fcflag4 }}</div>
                      <div class="fo">{{ item.fcname4 }}</div>
                    </template>
                  </van-checkbox>
                  <van-checkbox
                    v-if="item.fcflag5"
                    :name="item.fcflag5"
                    :checked-color="item.ys.headLcolor"
                  >
                    <template #default>
                      <div class="en">{{ item.fcflag5 }}</div>
                      <div class="fo">{{ item.fcname5 }}</div>
                    </template>
                  </van-checkbox>
                </van-checkbox-group>
                <!-- <div class="food_option_r">
                  <div class="en">{{item.fcflag1}}</div>
                  <div class="fo">{{item.fcname1}}</div>
                </div>
                <div class="food_option_r">
                  <div class="en">{{item.fcflag2}}</div>
                  <div class="fo">{{item.fcname2}}</div>
                </div>
                <div class="food_option_r">
                  <div class="en">{{item.fcflag3}}</div>
                  <div class="fo">{{item.fcname3}}</div>
                </div>
                <div class="food_option_r">
                  <div class="en">{{item.fcflag4}}</div>
                  <div class="fo">{{item.fcname4}}</div>
                </div>
                <div class="food_option_r">
                  <div class="en">{{item.fcflag5}}</div>
                  <div class="fo">{{item.fcname5}}</div>
                </div> -->
              </div>
            </div>
          </div>
        </div>
        <van-empty v-else description="暂无数据" />
      </div>
      <van-loading
        v-else
        type="spinner"
        vertical
        color="#2B8DF0"
        style="margin-top:40%;"
        >加载中...</van-loading
      >
    </div>
    <div class="footer" v-if="canOrder && menuinfo.length > 0">
      <van-button type="info" size="small" @click="savePersonInfo">{{
        $t('module.submit')
      }}</van-button>
    </div>
  </div>
</template>

<script>
import { Toast } from 'vant'
import { getEmpDayBookInfo, saveEmpDayBookInfo } from '@api/wx.js'
export default {
  data() {
    const userInfo = localStorage.userInfo
      ? JSON.parse(localStorage.userInfo)
      : {}
    return {
      userInfo,
      weeks: [],
      checkdate: '', // 选中的日期（dd）
      checkFullDate: '', // 选中的日期（yyMMdd）
      checked1: [],
      checked2: [],
      checked3: [],
      checked4: [],
      menuinfo: [], // 菜单信息
      zaocanInfo: [], // 早餐信息
      wucanInfo: [], // 中餐信息
      wancanInfo: [], // 晚餐信息
      yexiaoInfo: [], // 夜宵信息
      bookinfo: [], // 员工的点菜信息
      initbookinfo: [], // 初始的员工的点菜信息
      loading: false,
      style: [
        {
          icon: 'ordering01',
          bg: 'linear-gradient(to bottom, #eef5fe, #f6faff)',
          headLcolor: '#147ae1',
          headRbg: '#2b8df0'
        },
        {
          icon: 'ordering02',
          bg: 'linear-gradient(to bottom, #fff7d5, #fff9f3)',
          headLcolor: '#de6000',
          headRbg: '#ff8000'
        },
        {
          icon: 'ordering03',
          bg: 'linear-gradient(to bottom, #f2ffec, #f5f8f4)',
          headLcolor: '#3e8a3c',
          headRbg: '#009156'
        },
        {
          icon: 'ordering04',
          bg: 'linear-gradient(to bottom, #ffdcd5, #fefefe)',
          headLcolor: '#ff4040',
          headRbg: '#ff4740'
        }
      ]
    }
  },
  computed: {
    // 是否显示提交和编辑
    canOrder() {
      let newtime = new Date(
        this.formatDate(new Date(), 'yyyy-MM-dd')
      ).getTime()
      let todytime = new Date(this.checkFullDate).getTime()
      return todytime >= newtime
    }
  },
  created() {
    // 第一次进来默认当前日期
    let checkdate = new Date().getDate()
    if (checkdate < 10) {
      checkdate = '0' + checkdate
    }
    this.checkdate = checkdate
    this.checkFullDate = this.formatDate(new Date(), 'yyyy-MM-dd')
    // 根据当前日期获取本周日期
    this.getMonDayAndSunDay(new Date())
    // 获取当前日期的套餐信息
    this.getEmpDayBookInfoData(this.formatDate(new Date(), 'yyyy-MM-dd'))
  },
  methods: {
    // 获取员工某一天的订餐信息
    getEmpDayBookInfoData(yymmdd) {
      this.loading = true
      getEmpDayBookInfo({
        yymmdd: yymmdd,
        empid: this.userInfo.empid
      }).then(res => {
        let i = 0
        if (res.menuinfo.length > 0) {
          res.menuinfo.forEach((item, index) => {
            let l = index + 1
            if (item.zc_min_select == item.zc_max_select) {
              item.zcDesc = '（选' + item.zc_min_select + '项）'
            } else {
              item.zcDesc =
                '（选' + item.zc_min_select + '~' + item.zc_max_select + '项）'
            }
            if (item.fc_min_select == item.fc_max_select) {
              item.fcDesc = '（选' + item.fc_min_select + '项）'
            } else {
              item.fcDesc =
                '（选' + item.fc_min_select + '~' + item.fc_max_select + '项）'
            }
            item.zcChecked = []
            item.fcChecked = []
            if (i == 4) {
              i = 0
            }
            item.ys = this.style[i]
            i++
            if (res.bookinfo.length > 0) {
              if (res.bookinfo[0]['zcflag' + l]) {
                item.zcChecked = res.bookinfo[0]['zcflag' + l].split(',')
              }
              if (res.bookinfo[0]['fcflag' + l]) {
                item.fcChecked = res.bookinfo[0]['fcflag' + l].split(',')
              }
            }
          })
        }
        this.menuinfo = res.menuinfo
        this.bookinfo = res.bookinfo
        this.initbookinfo = JSON.parse(JSON.stringify(res.bookinfo))
        this.loading = false
        console.log(this.menuinfo, 'this.menuinfo')
        this.$forceUpdate()
      })
    },
    // 选择某一天
    getOneDay(it) {
      this.checkdate = it.date
      this.checkFullDate = it.fulldate
      this.getEmpDayBookInfoData(it.fulldate)
    },
    // 左右切换周
    arrows(val) {
      if (val == 'left') {
        // 根据当前weeks里的第一天获取上周日期
        let preDate = new Date(
          new Date(this.weeks[0].fulldate).getTime() - 24 * 60 * 60 * 1000
        ) //前一天
        this.getMonDayAndSunDay(this.formatDate(preDate, 'yyyy-MM-dd'))
      } else if (val == 'right') {
        // 根据当前weeks里的最后一天获取下周日期
        let nextDate = new Date(
          new Date(this.weeks[6].fulldate).getTime() + 24 * 60 * 60 * 1000
        ) //后一天
        this.getMonDayAndSunDay(this.formatDate(nextDate, 'yyyy-MM-dd'))
      }
      // 查询某一天数据
      let isToday = false
      let todayIdx = 0
      for (let i = 0; i < this.weeks.length; i++) {
        const it = this.weeks[i]
        if (it.fulldate == this.formatDate(new Date(), 'yyyy-MM-dd')) {
          isToday = true
          todayIdx = i
        }
      }
      // 如果是当前日期就选当前日期，否则选择一周的星期一
      if (isToday) {
        this.getOneDay(this.weeks[todayIdx])
      } else {
        this.getOneDay(this.weeks[0])
      }
    },
    // 根据某一天获取所在周的日期
    getMonDayAndSunDay(date) {
      let blank = [
        { week: '周一', date: '', fulldate: null },
        { week: '周二', date: '', fulldate: null },
        { week: '周三', date: '', fulldate: null },
        { week: '周四', date: '', fulldate: null },
        { week: '周五', date: '', fulldate: null },
        { week: '周六', date: '', fulldate: null },
        { week: '周日', date: '', fulldate: null }
      ]
      blank.forEach((el, idx) => {
        let fulldate = null
        if (new Date(date).getDay() > 0) {
          // 大于0表示不是星期日
          fulldate = new Date(
            new Date(date).getTime() -
              3600 * 1000 * 24 * (new Date(date).getDay() - (idx + 1))
          )
        } else {
          // 这是星期日
          fulldate = new Date(
            new Date(date).getTime() -
              3600 * 1000 * 24 * (new Date(date).getDay() - (idx - 6))
          )
        }
        el.fulldate = this.formatDate(fulldate, 'yyyy-MM-dd')
        let da = fulldate.getDate()
        if (da < 10) {
          da = '0' + da
        }
        el.date = da
      })
      this.weeks = blank
      // console.log(this.weeks, 'weeksweeks');
    },
    // 不够就补零
    add0(m) {
      return m < 10 ? '0' + m : m
    },
    // 日期时间转换格式
    formatDate(date, rule) {
      if (date) {
        const time = new Date(date)
        const y = time.getFullYear()
        const m = time.getMonth() + 1
        const d = time.getDate()
        const h = time.getHours()
        const mm = time.getMinutes()
        const s = time.getSeconds()
        if (rule == 'yyyy-MM-dd') {
          return y + '-' + this.add0(m) + '-' + this.add0(d)
        } else {
          return (
            y +
            '-' +
            this.add0(m) +
            '-' +
            this.add0(d) +
            ' ' +
            this.add0(h) +
            ':' +
            this.add0(mm)
          )
          // + ':' + this.add0(s)
        }
      } else {
        return ''
      }
    },
    // 返回
    onClickLeft() {
      if (this.$route.query.from == 'home') {
        this.$router.push('/home')
      } else {
        this.$router.push('/personCen')
      }
    },
    // 提交按钮
    savePersonInfo() {
      let data = []
      for (let m = 0; m < this.menuinfo.length; m++) {
        const it = this.menuinfo[m]
        if (it.zc_min_select && it.zcChecked.length < it.zc_min_select) {
          Toast.fail(it.xfname + '主菜至少选择' + it.zc_min_select + '项！')
          return
        }
        if (it.fc_min_select && it.fcChecked.length < it.fc_min_select) {
          Toast.fail(it.xfname + '辅菜至少选择' + it.fc_min_select + '项！')
          return
        }
        let i = m + 1
        let obj = {}
        obj.xfid = it.xfid
        obj.old_zcflag = this.initbookinfo[0]['zcflag' + i]
        obj.new_zcflag = it.zcChecked.join(',')
        obj.old_fcflag = this.initbookinfo[0]['fcflag' + i]
        obj.new_fcflag = it.fcChecked.join(',')
        data.push(obj)
      }
      saveEmpDayBookInfo({
        yymmdd: this.checkFullDate,
        empid: this.userInfo.empid,
        data: data
      }).then(res => {
        if (res.data[0].info == '') {
          Toast.success('订餐成功！')
        } else {
          Toast(res.data[0].info)
        }
      })
    },
    // 勾选了一个主菜 然后辅菜全部勾上
    zcchange(item) {
      item.fcChecked = []
      if (item.zcChecked.length > 0) {
        if (item.fcflag1) item.fcChecked.push(item.fcflag1)
        if (item.fcflag2) item.fcChecked.push(item.fcflag2)
        if (item.fcflag3) item.fcChecked.push(item.fcflag3)
        if (item.fcflag4) item.fcChecked.push(item.fcflag4)
        if (item.fcflag5) item.fcChecked.push(item.fcflag5)
      }
    }
  }
}
</script>

<style lang="less" scoped>
.weChatOrdering_box {
  height: 100vh;
  overflow: hidden;
  .van-nav-bar {
    background: #2b8df0;

    /deep/ .van-nav-bar__left {
      .van-icon {
        color: #fff;
      }
    }
    /deep/.van-nav-bar__title {
      color: #fff;
      font-size: 36px;
      font-family: Source Han Sans CN;
    }
  }
  .noFoot {
    overflow: auto;
    margin-top: 1.22667rem;
    height: calc(100% - 92px) !important;
  }
  .content {
    overflow: auto;
    margin-top: 1.22667rem;
    height: calc(100% - 192px);
    .head_date {
      display: flex;
      align-items: center;
      padding: 20px 0;
      background: #fff;
      .icon_box {
        display: flex;
        align-items: center;
        .van-icon {
          font-size: 40px;
          font-weight: bold;
        }
      }
      .date_box {
        width: calc((100% - 40px) / 7);
        font-size: 28px;
        text-align: center;
        .week {
          margin-bottom: 10px;
        }
        .date {
          display: flex;
          justify-content: center;
          align-items: center;
          .text {
            width: 50px;
            height: 50px;
            line-height: 50px;
            border-radius: 50%;
          }
          .bgtext {
            color: #fff;
            background: #2b8df0;
          }
        }
      }
    }
    .comm_food_box {
      // height: 300px;
      padding: 20px 0 20px 20px;
      margin: 30px 20px;
      border-radius: 20px;
      box-shadow: 0px 6px 12px rgba(132, 132, 143, 0.31);
      .food_head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;
        .head_left {
          display: flex;
          align-items: center;
          font-size: 40px;

          font-weight: bold;
          .svg-icon {
            margin-right: 20px;
          }
        }
        .head_right {
          .checkd_food {
            font-size: 24px;
            height: 50px;
            line-height: 50px;
            color: #fff;
            padding: 0 20px;
            border-radius: 100px 0px 0px 100px;
          }
        }
      }
      .food_body {
        display: flex;
        .body_left {
          flex: 1;
          border-right: 4px solid #f1e9e2;
          .left_title {
            margin-bottom: 20px;
            font-size: 34px;
            font-weight: bold;
          }
          .van-checkbox-group {
            .van-checkbox {
              margin-bottom: 20px;
              /deep/.van-checkbox__label {
                display: flex;
                align-items: center;
                line-height: unset;
                color: #2c2c2c;
                .en {
                  font-size: 32px;
                  margin-right: 10px;
                }
                .fo {
                  font-size: 32px;
                }
              }
            }
          }
        }
        .body_right {
          flex: 1;
          padding-left: 20px;
          .right_title {
            margin-bottom: 20px;
            font-size: 34px;
            font-weight: bold;
          }
          .van-checkbox-group {
            .van-checkbox {
              margin-bottom: 20px;
              /deep/.van-checkbox__label {
                display: flex;
                align-items: center;
                line-height: unset;
                color: #2c2c2c;
                .en {
                  font-size: 32px;
                  margin-right: 10px;
                }
                .fo {
                  font-size: 32px;
                }
              }
            }
          }
          .food_option_r {
            display: flex;
            align-items: center;
            // font-size: 34px;
            margin-bottom: 20px;
            color: #2c2c2c;
            .en {
              font-size: 32px;
              margin-right: 10px;
            }
            .fo {
              font-size: 32px;
            }
          }
        }
      }
    }
    .breakfast_box {
      background: linear-gradient(to bottom, #eef5fe, #f6faff);
      .food_head {
        .head_left {
          color: #147ae1;
        }
        .head_right {
          .checkd_food {
            background: #2b8df0;
          }
        }
      }
    }
    .lunch_box {
      background: linear-gradient(to bottom, #fff7d5, #fff9f3);
      .food_head {
        .head_left {
          color: #de6000;
        }
        .head_right {
          .checkd_food {
            background: #ff8000;
          }
        }
      }
    }
    .dinner_box {
      background: linear-gradient(to bottom, #f2ffec, #f5f8f4);
      .food_head {
        .head_left {
          color: #3e8a3c;
        }
        .head_right {
          .checkd_food {
            background: #009156;
          }
        }
      }
    }
    .mid_snack_box {
      background: linear-gradient(to bottom, #ffdcd5, #fefefe);
      .food_head {
        .head_left {
          color: #ff4040;
        }
        .head_right {
          .checkd_food {
            background: #ff4740;
          }
        }
      }
    }
  }
  .footer {
    position: absolute;
    background-color: #ffffff;
    bottom: 0;
    height: 100px;
    width: 100%;
    display: flex;
    align-items: center;
    /* text-align: center; */
    justify-content: center;
    .van-button {
      width: 60%;
      border-radius: 12px;
      .van-button__text {
        font-size: 36px;
        font-family: Source Han Sans SC;
        font-weight: 500;
      }
    }
  }
}
</style>
